import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error ,listings,proListings,isAuthenticated,proLastedListings,companyLastedListings,currentUser} = props;

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      listings={listings}
      proListings={proListings}
      proLastedListings={proLastedListings}
      isAuthenticated={isAuthenticated}
      companyLastedListings={companyLastedListings}
      currentUser={currentUser}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const{currentPageResultIds,proResultIds,proLastedResultIds,companyLastedResultIds} = state.landingPage || {};
  const{currentUser} = state.user || {};
  const { isAuthenticated } = state.auth;
  const listings = getListingsById(state, currentPageResultIds);
  const proListings = getListingsById(state, proResultIds);
  const proLastedListings = getListingsById(state, proLastedResultIds);
  const companyLastedListings = getListingsById(state, companyLastedResultIds);
  return { pageAssetsData, inProgress, error,listings,proListings,currentUser,proLastedListings ,companyLastedListings,isAuthenticated};
};



// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
